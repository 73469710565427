<script setup lang="ts">
import delve from 'dlv'

const { find } = useStrapi()

const { data: employeesResponse } = await useCachedAsyncData('employees-response', () =>
  find('employees'),
)


const employees = delve(employeesResponse.value, 'data', [])

const currentIndex = ref(0)

const next = () => {
  currentIndex.value = (currentIndex.value + 1) % employees.length
}

const startSlider = () => {
  setInterval(() => {
    next()
  }, 2000)
}

onMounted(() => {
  startSlider()
})
</script>

<template>
  <div
    class="team-slider-container"
  >
    <template
      v-for="(employee, index) in employees"
    >
        <Transition name="fade">
          <div
            v-if="employee.attributes.picture.data && index === currentIndex"
            class="team-picture"
          >
            <NuxtImg
              width="64"
              loading="lazy"
              format="webp"
              :src="getStrapiMedia(employee.attributes.picture.data.attributes.url)"
              :alt="employee.attributes.name"
            />
          </div>
        </Transition>
    </template>

  </div>
</template>

<style lang="scss">
.team-slider-container {
  position: relative;
  overflow: hidden;
  width: 4rem;
  height: 4rem;

  .team-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;

    img {
      max-width: 4rem;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 1s ease;
  }
}
</style>
